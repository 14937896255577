"use client"

import useAuth from "@/hooks/useAuth";
import Logo from "../Logo"

interface IconAssistantProps {
    width?: number,
    height?: number,
    className?: string,
}

export default function IconAssistant({ width, height, className }: IconAssistantProps) {
    const { isPremiumMember } = useAuth();
    return (
        <div className={`w-[33px] h-[33px] flex justify-center items-center rounded-full border ${className ?? ''} ${isPremiumMember ? "bg-primary-1 border-primary-5" : "bg-white border-grey-1 shadow-drop-shadow"}`}>
            <div className="h-[14px] w-4 flex justify-center items-center">
                <Logo color={isPremiumMember ? "white" : "blue"} width={width} height={height} />
            </div>
        </div>
    )
}