"use client"

import Skeleton from "../Skeleton"
import Avatar from "../Avatar"
import UserBadge from "../UserBadge"
import useAuth from "@/hooks/useAuth";

interface ProfilePreviewProps {
    firstName?: string,
    lastName?: string,
    text?: string,
    image?: string,
    className?: string,
    loading?: boolean
}

export default function ProfilePreview({ firstName, lastName, text, image, loading = false, className }: ProfilePreviewProps) {
    const { isPremiumMember } = useAuth()
    return (
        <>
            {
                loading ?
                    <Skeleton className="h-[131px]" />
                    :
                    <div className={` min-h-[131px] w-full ${isPremiumMember ? "bg-primary-5 border-highlight" : "bg-grey-1 border-grey-1.1"} border rounded-sm flex flex-row items-center justify-center px-6 py-5 ${className ?? ''}`}>
                        <div>
                            {
                                image ?
                                    <Avatar url={image} size="!w-[89px] h-[89px] z-10" isHighlight={isPremiumMember} />
                                    :
                                    <div className="w-[89px]">
                                        <div className={`border ${isPremiumMember ? "border-highlight" : "border-grey-2.2"} bg-white w-[89px] h-[89px] rounded-full flex items-center justify-center notranslate`}>
                                            <p className={`text-primary-2 font-bold text-2xl`}>{`${firstName && firstName.substring(0, 1).toUpperCase()}${lastName && lastName.substring(0, 1).toUpperCase()}`}</p>
                                        </div>
                                    </div>
                            }
                        </div>
                        <div className="flex flex-col items-start justify-center pl-6 w-[calc(100%_-_89px)]">
                            <UserBadge />
                            <p className={`text-xl font-bold ${isPremiumMember ? "text-white" : "text-primary-2"} notranslate`}>{firstName} {lastName}</p>
                            {
                                text ?
                                    <p className={`text-xs font-normal ${isPremiumMember ? "text-white" : "text-primary-1"}`}>“{text}”</p>
                                    : null
                            }
                        </div>
                    </div>
            }
        </>
    )
}