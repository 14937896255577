"use client"

import { CancelBookMeetingAction } from "@/interfaces/firebase";
import MessageAssistant from "../MessageAssistant";
import { Timestamp } from "firebase/firestore";
import { formatFirestoreTimestamp } from "@/functions/format";

export default function CancelMeeting({ meeting }: CancelBookMeetingAction) {
    let meet: any = meeting
    const hour = formatFirestoreTimestamp(new Timestamp(meet.start._seconds, meet.start._nanoseconds))

    return (
        <div>
            <MessageAssistant message={
                <span className="font-normal text-13px text-primary-1">
                    I've cancelled the <span className="font-bold text-primary-3 underline">{hour}.</span>
                </span>
            } />
        </div>
    )
}