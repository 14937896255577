import IconIA from "../IconIA"

interface SuggestionButtonProps {
    text: string;
    showIconAI?: boolean;
    className?: string;
    colorText?: string;
    colorIconAI?: string;
    onClick: (text: string) => void;
}

export default function SuggestionButton({ text, showIconAI = true, className, colorText, colorIconAI, onClick }: SuggestionButtonProps) {
    return (
        <div className={`py-3 px-[30px] rounded-full bg-grey-1 border-2 border-[#E0EAFB] relative ${className ?? ""}`} onClick={() => onClick(text)}>
            <p className={`font-medium text-15px ${colorText ? colorText : "text-[#4E6280]"} text-nowrap`}>{text}</p>
            {showIconAI && <IconIA className={`${colorIconAI ? colorIconAI : "text-primary-3.2"} absolute top-0 right-0`} />}
        </div>
    )
}