interface IconTalkProps {
    className?: string,
}

export default function IconTalk({ ...props }: IconTalkProps) {
    return (
        <svg width="15" height="19" viewBox="0 0 15 19" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path d="M7.5 0C8.35248 0 9.17005 0.316071 9.77284 0.87868C10.3756 1.44129 10.7143 2.20435 10.7143 3V9C10.7143 9.79565 10.3756 10.5587 9.77284 11.1213C9.17005 11.6839 8.35248 12 7.5 12C6.64752 12 5.82995 11.6839 5.22716 11.1213C4.62436 10.5587 4.28571 9.79565 4.28571 9V3C4.28571 2.20435 4.62436 1.44129 5.22716 0.87868C5.82995 0.316071 6.64752 0 7.5 0ZM15 9C15 12.53 12.2036 15.44 8.57143 15.93V19H6.42857V15.93C2.79643 15.44 0 12.53 0 9H2.14286C2.14286 10.3261 2.70727 11.5979 3.71193 12.5355C4.71659 13.4732 6.0792 14 7.5 14C8.9208 14 10.2834 13.4732 11.2881 12.5355C12.2927 11.5979 12.8571 10.3261 12.8571 9H15Z" fill="currentColor" />
        </svg>
    )
}