interface IconInformationProps {
    className?: string,
}

export default function IconInformation({...props}: IconInformationProps) {
    return (
        <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path d="M5.85 9.75H7.15V5.85H5.85V9.75ZM6.5 4.55C6.68416 4.55 6.83865 4.4876 6.96345 4.3628C7.08825 4.238 7.15043 4.08373 7.15 3.9C7.14956 3.71627 7.08716 3.562 6.9628 3.4372C6.83843 3.3124 6.68416 3.25 6.5 3.25C6.31583 3.25 6.16156 3.3124 6.0372 3.4372C5.91283 3.562 5.85043 3.71627 5.85 3.9C5.84956 4.08373 5.91196 4.23822 6.0372 4.36345C6.16243 4.48868 6.3167 4.55087 6.5 4.55ZM6.5 13C5.60083 13 4.75583 12.8293 3.965 12.4878C3.17417 12.1463 2.48625 11.6833 1.90125 11.0987C1.31625 10.5142 0.853234 9.82626 0.512201 9.035C0.171167 8.24373 0.000434156 7.39873 8.22784e-07 6.5C-0.00043251 5.60126 0.170301 4.75627 0.512201 3.965C0.8541 3.17373 1.31712 2.48582 1.90125 1.90125C2.48538 1.31668 3.1733 0.853666 3.965 0.5122C4.7567 0.170733 5.6017 0 6.5 0C7.3983 0 8.24329 0.170733 9.03499 0.5122C9.82669 0.853666 10.5146 1.31668 11.0987 1.90125C11.6829 2.48582 12.1461 3.17373 12.4884 3.965C12.8308 4.75627 13.0013 5.60126 13 6.5C12.9987 7.39873 12.828 8.24373 12.4878 9.035C12.1476 9.82626 11.6846 10.5142 11.0987 11.0987C10.5129 11.6833 9.82496 12.1465 9.03499 12.4884C8.24503 12.8303 7.40003 13.0009 6.5 13Z" fill="currentColor" />
        </svg>
    )
}