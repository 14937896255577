interface IconArrowRightProps {
    color?: string,
    width?: string,
    className?: string
}

export default function IconArrowRight({ color = "#004ECB", width = "2", className }: IconArrowRightProps) {
    return (
        <svg width="6" height="8" viewBox="0 0 6 8" fill="none" xmlns="http://www.w3.org/2000/svg" className={`${className ?? ""}`}>
            <path d="M1 7L4 4L1 1" stroke={color} strokeWidth={width} />
        </svg>
    )
}