import { Deal } from "@/interfaces/firebase"

interface RequestedDealProps {
    deal: Deal;
    introduction: string;
}

export default function RequestedDeal({ deal, introduction }: RequestedDealProps) {
    return (
        <div className="w-full py-5 border-t border-b border-grey-1.1 flex flex-col justify-center items-center">
            <p className="font-medium text-sm text-grey-3">Requested to join deal:</p>
            <p className="font-bold text-sm text-primary-3.1">{deal.title}</p>
            <p className="font-medium text-sm text-primary-1">{introduction}</p>
        </div>
    )
}