import { Deal } from "@/interfaces/firebase"

interface RequestDealProps {
    deal: Deal
}

export default function RequestDeal({ deal }: RequestDealProps) {
    return (
        <div className="w-full py-5 border-t border-b border-grey-1.1 flex flex-col justify-center items-center">
            <p className="font-medium text-sm text-grey-3">Requesting to join deal:</p>
            <p className="font-bold text-sm text-primary-3.1">{deal.title}</p>
        </div>
    )
}