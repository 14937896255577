import useAuth from "@/hooks/useAuth";
import Alert from "../Alert";
import MessageAssistant from "../MessageAssistant";
import Profile from "../Profile";
import { useRouter } from "next/navigation";
import { useState } from "react";
import Loading from "../Loading";

interface ThirdCardOnboardingProps {
    onClickMessage?: () => void,
}

export default function ThirdCardOnboarding({ onClickMessage }: ThirdCardOnboardingProps) {
    const { currentUser } = useAuth()
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const route = useRouter()

    const goToProfileOverview = () => {
        route.push('/profile/overview-profile')
    }

    const handleClickMekeProfileLive = () => {
        setIsLoading(true)
        onClickMessage?.()
    }

    return (
        <div className="pb-8">
            <div className="space-y-[31px]">
                <MessageAssistant message={<>Here's a <span className="text-primary-3">simple profile</span> I've made for you:</>} />
                <Profile profile={currentUser} onClick={goToProfileOverview} />
                <Alert text={<>Is there anything you'd like to change? <span className="font-bold text-black-2">Click the card to to make more changes.</span></>} />
                <hr className="border-[#E7EFFF]" />
            </div>
        </div>
    )
}