import { motion } from "framer-motion";

const loadingContainerVariants = {
    start: {
        transition: {
            staggerChildren: 0.2
        }
    },
    end: {
        transition: {
            staggerChildren: 0.2
        }
    }
};

const loadingCircleVariants = {
    start: {
        opacity: 0.2,
        // y: "70%"
    },
    end: {
        opacity: 1,
        // y: "120%"
    }
};

const loadingCircleTransition = {
    duration: 0.5,
    repeat: Infinity,
    repeatType: "mirror" as const,
    ease: "easeInOut"
};

interface LoadingDotsProps {
    className?: string;
}

export default function LoadingDots({ className }: LoadingDotsProps) {
    return (
        <motion.div
            className={`flex justify-around items-center ${className ? "w-1/2" : "w-6"}`}
            variants={loadingContainerVariants}
            initial="start"
            animate="end"
        >
            <motion.span
                className={`block w-1 h-1 bg-[#D6E5FF] ${className ?? ""}`}
                variants={loadingCircleVariants}
                transition={loadingCircleTransition}
            />
            <motion.span
                className={`block w-1 h-1 bg-[#96BEFF] ${className ?? ""}`}
                variants={loadingCircleVariants}
                transition={loadingCircleTransition}
            />
            <motion.span
                className={`block w-1 h-1 bg-[#3272D8] ${className ?? ""}`}
                variants={loadingCircleVariants}
                transition={loadingCircleTransition}
            />
        </motion.div>
    );
}
