import useAuth from "@/hooks/useAuth";
import MessageAssistant from "../MessageAssistant"
import { IoIosInformationCircle } from "react-icons/io";
import Markdown from "react-markdown"
import { v4 as uuidv4 } from 'uuid';

export default function SecondCardOnboarding() {
    const { currentUser } = useAuth()
    return (
        <div className="flex flex-col justify-between space-y-6 flex-1">
            <div className="space-y-6">
                <MessageAssistant
                    message={<>
                        Below is a <span className="font-extrabold">first draft</span> of profile, based on <span className="font-extrabold">publicly available information.</span><br /><br />
                        <span className="font-extrabold">Our next step is to make any edits you see fit.</span>
                    </>}
                />
                <div className="w-full bg-[#FFECD7] py-4 px-[7px] rounded-sm flex flex-col space-y-3">
                    <div className="flex flex-row space-x-2 items-center px-[10px]">
                        <IoIosInformationCircle className="text-[#EB9131] h-3 w-3" />
                        <p className="text-[#9E6931] text-sm font-bold">NOT YET LIVE</p>
                    </div>
                    <p className="text-black-3 text-sm font-bold px-[10px]">
                        <span className="text-black-2">You haven’t fully validated his profile yet.</span> Information here might be innacurate or outdated.
                    </p>
                    <div className="w-full h-auto px-[10px] py-4 rounded-sm bg-white">
                        <Markdown className={"font-normal text-sm text-black-2 leading-[150%]"} key={uuidv4()}>{currentUser.aiLongSummary}</Markdown>
                    </div>
                    <p className="text-black-3 text-sm font-bold px-[10px]">
                        You won’t be visible to others until your profile has been verified. You can <span className="text-black-2 font-extrabold">always edit your profile.</span>
                    </p>
                </div>
                <p className="font-medium text-base text-primary-3 leading-[140%]">
                    Are there any inaccuracies? What would you change? What would you like to highlight?<br /><br />
                    Let me know if you’re happy to make it live.
                </p>
            </div>
        </div>
    )
}