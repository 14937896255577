"use client"

import { useEffect, useRef, useState } from "react";
import ReactCrop, { Crop, PixelCrop } from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import Loading from "../Loading";
import { getCroppedImg } from "@/functions/cropImage";
import Modal from "../Modal";
import useAuth from "@/hooks/useAuth";

export interface ChooseImageProps {
    loading?: boolean,
    disable?: boolean,
    onSelected: (file: File) => void,
    setFileExplorerOpen?: (isOpen: boolean) => void,
    onBlur?: (blur: boolean) => void,
}

export default function ChooseImage({ loading = false, disable = false, onSelected, setFileExplorerOpen, onBlur }: ChooseImageProps) {
    const [imageSrc, setImageSrc] = useState<string | null>(null);
    const [crop, setCrop] = useState<Crop>({ unit: "%", width: 50, height: 50, x: 25, y: 25 });
    const [completedCrop, setCompletedCrop] = useState<PixelCrop | null>(null);
    const [imageFile, setImageFile] = useState<File | null>(null);
    const [isCropping, setIsCropping] = useState(false);
    const imgRef = useRef<HTMLImageElement | null>(null);
    const { isPremiumMember } = useAuth()
    const fileInputRef = useRef<HTMLInputElement>(null);

    const handleClick = () => {
        if (disable || loading) return;
        if (fileInputRef.current) {
            fileInputRef.current.value = "";
            setFileExplorerOpen?.(true);
            onBlur?.(true);
            fileInputRef.current.addEventListener('change', handleFileChange);
            fileInputRef.current.click();
        }
    };

    const handleFileChange = (e: Event) => {
        setFileExplorerOpen?.(false)
        const input = e.target as HTMLInputElement;
        if (input && input.files && input.files.length > 0) {
            const reader = new FileReader();
            const file = input.files[0];
            reader.addEventListener('load', () => setImageSrc(reader.result as string));
            reader.readAsDataURL(file);
            setImageFile(file);
            setIsCropping(true);
        }
    };

    const handleCropComplete = (crop: PixelCrop) => {
        setCompletedCrop(crop);
    };

    const handleCropConfirm = async () => {
        if (!imageSrc || !imgRef.current) return;
        if (!completedCrop) {
            if (imageFile) onSelected(imageFile)
            setImageSrc(null)
            setIsCropping(false)
            return
        }

        const croppedBlob = await getCroppedImg(imageSrc, imgRef.current, completedCrop);
        if (croppedBlob) {
            const file = new File([croppedBlob], `${imageFile?.name || "croppedImage"}.jpg`, { type: "image/jpeg" });
            onSelected(file);
        }
        setImageSrc(null);
        setIsCropping(false);
    };

    const onImageLoad = () => {
        if (imgRef.current) {
            const { width, height } = imgRef.current;
            const cropSize = Math.min(width, height);
            setCrop({
                unit: "px",
                width: cropSize,
                height: cropSize,
                x: (width - cropSize) / 2,
                y: (height - cropSize) / 2,
            });
        }
    };

    useEffect(() => {
        const handleFocusChange = () => {
            if (document.activeElement !== fileInputRef.current) {
                onBlur?.(false)
            }
        }
        document.addEventListener('focus', handleFocusChange, true);
        return () => {
            document.removeEventListener('focus', handleFocusChange, true)
        }
    }, [onBlur])

    return (
        <>
            {isCropping && (
                <Modal show={isCropping} onClose={() => setIsCropping(false)} title="Adjust your image" titleClassName={isPremiumMember ? "!text-white" : ""} className={`w-full ${isPremiumMember ? "!bg-primary-5" : ""}`}>
                    <div className="relative w-full h-[400px] flex items-center justify-center mt-3">
                        {imageSrc && (
                            <div className="relative">
                                <ReactCrop
                                    aspect={1}
                                    crop={crop}
                                    onChange={(newCrop) => setCrop(newCrop)}
                                    onComplete={handleCropComplete}
                                    minWidth={50}
                                    minHeight={50}
                                    keepSelection={true}
                                    circularCrop={true}
                                    ruleOfThirds
                                >
                                    <img
                                        ref={imgRef}
                                        alt="Source"
                                        src={imageSrc}
                                        onLoad={onImageLoad}
                                        style={{
                                            maxHeight: "400px",
                                            maxWidth: "100%",
                                            objectFit: "contain"
                                        }}
                                    />
                                </ReactCrop>
                            </div>
                        )}
                    </div>
                    <div className={`${isPremiumMember ? "bg-primary-1 border-primary-5" : "bg-primary-3 border-primary-4"} w-full rounded-sm flex flex-row justify-center items-center h-[40px] px-[19px] py-[13px] mt-[15px] border`} onClick={handleCropConfirm}>
                        <p className="font-bold text-sm text-white">Save</p>
                    </div>
                </Modal>
            )}

            <div
                className={`min-w-[100px] min-h-[50px] flex items-center justify-center cursor-pointer border-dashed border-2 ${disable ? "border-grey-2.2" : "border-primary-3"} rounded-sm bg-white shadow-drop-shadow`}
                onClick={handleClick}
            >
                {loading ? <Loading /> : <p className="font-normal text-sm text-black-2 leading-[150%]">{disable ? "The image has uploaded successfully!" : "Tap to choose image"}</p>}
                <input ref={fileInputRef} type="file" className="hidden" accept=".jpeg, .png, .jpg" />
            </div>
        </>
    );
}
