"use client"

import { actionAI } from "@/functions/actionChecker"
import LoadingDots from "../LoadingDots"
import { useEffect, useState } from "react";

interface PreLoadingComponentsProps {
    messages: string[],
    action: string
}
export default function PreLoadingComponents({ messages, action }: PreLoadingComponentsProps) {
    const [progress, setProgress] = useState(0);

    useEffect(() => {
        const interval1 = setInterval(() => {
            setProgress(prev => {
                if (prev < 90) {
                    return prev + 1.5;
                } else {
                    clearInterval(interval1);
                    return prev;
                }
            });
        }, 222);

        const interval2 = setTimeout(() => {
            const finalInterval = setInterval(() => {
                setProgress(prev => {
                    if (prev < 100) {
                        return prev + 0.17;
                    } else {
                        clearInterval(finalInterval);
                        return prev;
                    }
                });
            }, 1000);
        }, 20000);

        return () => {
            clearInterval(interval1);
            clearTimeout(interval2);
        };
    }, []);

    return (
        <div className="snap-x flex flex-nowrap overflow-hidden scrollbar-thin space-x-4">
            {
                messages.map((message, key) => {
                    const sizeCard = actionAI[action] ? actionAI[action].component : "small"
                    return (
                        <div key={key + message} className={`${messages.length > 1 ? "w-[277px]" : "w-full"} ${sizeCard == "large" ? "min-h-[153px]" : "h-full"} flex flex-row justify-start items-start p-[15px] bg-grey-1.1 flex-shrink-0 animate-flash relative`}>
                            <div className="flex flex-row items-start space-x-3">
                                <div className="pt-2">
                                    <LoadingDots />
                                </div>
                                <p className="text-grey-3 font-medium text-13px">{message}</p>
                            </div>
                            <div className="absolute bottom-0 left-0 w-full h-[10px]">
                                <div
                                    className="h-full bg-[#96BEFF] transition-all duration-1000 ease-linear"
                                    style={{ width: `${progress}%` }}
                                ></div>
                            </div>
                        </div>
                    )
                })
            }
        </div>
    )
}