import { create } from 'zustand'
import { persist } from "zustand/middleware"

export type Iscroll = {
    positions: scrollPosition,
}

export type scrollPosition = {
    [x: string]: number
}

export type Actions = {
    set: (id: string, scrollPosition: number) => void
}

export const useScroll = create<Iscroll & Actions>()(
    persist((set, get) => ({
        positions: {},
        set: (id: string, scrollPosition: number) => {
            set((state) => {
                let previous: scrollPosition = state.positions
                previous[id] = scrollPosition
                return {
                    positions: previous
                }
            })
        }
    }),
        {
            name: 'scrollPositions',
        })
)