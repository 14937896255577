import { Crop } from "react-image-crop";

export const getCroppedImg = (imageSrc: string, imageTag: HTMLImageElement, crop: Crop): Promise<Blob | null> => {
    return new Promise((resolve, reject) => {
        const image = new Image();
        image.src = imageSrc;
        image.onload = () => {
            const canvas = document.createElement("canvas");
            
            const scaleX = image.naturalWidth / imageTag.width;
            const scaleY = image.naturalHeight / imageTag.height;
            canvas.width = crop.width!;
            canvas.height = crop.height!;

            const ctx = canvas.getContext("2d");

            if (!ctx) {
                return reject(null);
            }

            ctx.drawImage(
                image,
                crop.x * scaleX,
                crop.y * scaleY,
                crop.width! * scaleX,
                crop.height! * scaleY,
                0,
                0,
                crop.width!,
                crop.height!
            );
            canvas.toBlob((blob) => {
                resolve(blob);
            }, "image/jpeg");
        };

        image.onerror = (error) => reject(error);
    });
};