interface IconRoleProps {
    className?: string,
}

export default function IconRole({ ...props }: IconRoleProps) {
    return (
        <svg width="9" height="9" viewBox="0 0 9 9" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path d="M0.0978042 8.4315C0.260022 8.8105 0.533708 9 0.918862 9H7.26258C7.64803 9.00033 7.92187 8.81083 8.08409 8.4315C8.24631 8.05217 8.20658 7.7 7.96492 7.375L5.45009 4V1H5.90321C6.03159 1.00067 6.13913 0.952833 6.22583 0.8565C6.31253 0.760167 6.35603 0.641333 6.35633 0.5C6.35663 0.358667 6.31328 0.24 6.22629 0.144C6.13929 0.0480001 6.03159 0 5.90321 0H2.27823C2.15045 0 2.04291 0.0480001 1.95561 0.144C1.8683 0.24 1.8248 0.358667 1.82511 0.5C1.82541 0.641333 1.86891 0.76 1.95561 0.856C2.0423 0.952 2.14984 1 2.27823 1H2.73135V4L0.216522 7.375C-0.0248409 7.70033 -0.0644136 8.0525 0.0978042 8.4315Z" fill="currentColor"/>
        </svg>
    )
}