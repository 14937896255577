"use client";

import IconLocation from "@/components/IconLocation";
import IconRole from "@/components/IconRole";
import IconGlobe from "@/components/IconGlobe";
import { User } from "@/interfaces/firebase";
import Avatar from "../Avatar";
import { useMemo } from "react";
import UserBadge from "../UserBadge";

interface ProfileProps {
  profile: User;
  className?: string;
  loading?: boolean;
  onClick?: () => void;
}

export default function Profile({
  profile,
  className,
  loading,
  ...props
}: ProfileProps) {
  const tier = useMemo((): string => {
    if (profile?.tierAchieved) {
      return profile?.tierAchieved;
    } else if (profile?.tier) {
      return profile?.tier;
    }
    return "Member";
  }, [profile]);

  if (!profile) {
    return;
  }

  return (
    <div
      className={`flex flex-col  h-auto justify-between ${profile?.tier == "Sponsor" ? "bg-primary-5 border-highlight" : "bg-grey-1 border-grey-1.1"} border shadow-default rounded-sm pl-[18px] pr-[14px] py-[26px] space-y-4`}
      {...props}
    >
      <div className="space-y-[23px]">
        <div className="flex flex-row w-full justify-start">
          {profile?.avatarUrl ? (
            <Avatar
              url={profile?.avatarUrl}
              size="!w-[100px] h-[100px] z-10"
              isHighlight={profile?.tier == "Sponsor"}
            />
          ) : (
            <div className="w-[100px] h-[100px] rounded-full border-grey-1.1 border-[3px] bg-white flex justify-center items-center notranslate">
              <p className="text-primary-3 font-bold text-3xl">{`${profile?.firstName?.substring(0, 1).toUpperCase()}${profile?.lastName?.substring(0, 1).toUpperCase()}`}</p>
            </div>
          )}
          <div className="w-[calc(100%_-_100px)] pl-[23px]">
            <UserBadge badge={tier} />
            <p
              className={`text-21px font-bold leading-[135%] ${profile?.tier == "Sponsor" ? "text-white" : "text-primary-2"} line-clamp-2 notranslate`}
            >
              {profile?.firstName} {profile?.lastName}
            </p>
            <p
              className={`text-15px font-medium leading-[135%] ${profile?.tier == "Sponsor" ? "text-white" : "text-black-2"} line-clamp-1`}
            >
              {profile?.professionalRoles?.length
                ? profile?.professionalRoles[0]
                : profile?.professionalRoles}
            </p>
          </div>
        </div>
        <div className="space-y-[6px]">
          <div className="flex flex-row items-center space-x-2">
            <IconRole
              className={`${profile?.tier == "Sponsor" ? "text-highlight" : "text-primary-3.2"}`}
            />
            <p
              className={`w-[calc(100%_-_18px)] font-medium leading-[140%] text-xs text-black-2 ${profile?.tier == "Sponsor" ? "text-white" : "text-black-2"} line-clamp-2`}
            >
              {profile?.aiOverview}
            </p>
          </div>
          <div className="flex flex-row items-center space-x-2">
            <IconLocation
              className={`${profile?.tier == "Sponsor" ? "text-highlight" : "text-primary-3.2"}`}
            />
            <p
              className={`w-[calc(100%_-_18px)] font-medium leading-[140%] text-xs ${profile?.tier == "Sponsor" ? "text-white" : "text-black-2"} line-clamp-1`}
            >
              {profile?.location}
            </p>
          </div>
          {
            profile?.languages?.length ?
              <div className="flex flex-row items-center space-x-2">
                <IconGlobe
                  className={`${profile?.tier == "Sponsor" ? "text-highlight" : "text-primary-3.2"}`}
                />
                <p
                  className={`w-[calc(100%_-_18px)] font-medium leading-[140%] text-xs ${profile?.tier == "Sponsor" ? "text-white" : "text-black-2"} line-clamp-1`}
                >
                  {profile.languages.join(", ")}
                </p>
              </div>
              : null
          }
        </div>
      </div>
      <p
        className={`font-normal text-sm ${profile?.tier == "Sponsor" ? "text-white" : "text-black-2"} leading-[150%]`}
      >
        {profile?.aiShortSummary}
      </p>
    </div>
  );
}
