require('dotenv').config()

interface sendingToAIProps {
	token: string
	id: string,
	conversationId: string
}

export const sendMessageToAI = async ({ token, id, conversationId }: sendingToAIProps) => {
	try {
		const response = await fetch(`${process.env.NEXT_PUBLIC_API_LINK}/chat`, {
			method: "POST",
			headers: {
				"authorization": `Bearer ${token}`,
				"content-type": "application/json"
			},
			body: JSON.stringify({ id: id, conversationId: conversationId })
		});
		const status = response.status;
		console.log(status)
	}
	catch (e) {
		return console.log(e)
	}
}