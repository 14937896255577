"use client";

import { OfferBookMeetingAction, User } from "@/interfaces/firebase";
import MessageAssistant from "../MessageAssistant";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import useAuth from "@/hooks/useAuth";
import { db } from "@/connectors/firebase";
import { doc, getDoc, Timestamp } from "firebase/firestore";
import { formatFirestoreTimestamp, getEventDay } from "@/functions/format";

export default function OfferMeeting({ meeting }: OfferBookMeetingAction) {
  let meet: any = meeting;
  const hour = formatFirestoreTimestamp(new Timestamp(meet.start._seconds, meet.start._nanoseconds))

  const daysOfTheWeek = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];

  const dayOfTheWeek =
    daysOfTheWeek[
    dayjs(new Timestamp(meet.start._seconds, meet.start._nanoseconds).seconds * 1000).utc().tz("Asia/Dubai").day()
    ];

  const dayOfTheMonth = formatFirestoreTimestamp(new Timestamp(meet.start._seconds, meet.start._nanoseconds), "DD MMMM YYYY")
  const day = getEventDay(new Timestamp(meet.start._seconds, meet.start._nanoseconds))
  const { currentUser } = useAuth();
  const [user, setUser] = useState<User>();

  useEffect(() => {
    getUser();
  }, []);

  const getUser = async () => {
    let user = meeting.participants.find(
      (r) => r !== currentUser.id && r !== `RAI`
    );
    if (user) {
      const userRef = doc(db, "users", user);
      const userSnap = await getDoc(userRef);
      setUser(userSnap.data() as User);
    }
  };

  return (
    <div>
      <MessageAssistant
        message={
          <>
            <span className="font-normal text-13px text-primary-1">
              You are both available for a meeting at{" "}
              <span className="font-bold text-primary-3">
                {hour} on {dayOfTheWeek}, {dayOfTheMonth}.
                {
                  day && <span className="pl-1">({day})</span>
                }
              </span>
              <br />
              <br />
              <span className="font-bold text-primary-3">
                Should I add it to your schedules?.
              </span>
            </span>
          </>
        }
      />
    </div>
  );
}
