import { useState } from "react";
import IconAssistant from "../IconAssistant";
import { TbRefresh } from "react-icons/tb";
import Loading from "../Loading";

interface TryAgainAIProps {
    onClick: () => void
}

export default function TryAgainAI({ onClick }: TryAgainAIProps) {

    const [isLoading, setIsLoading] = useState<boolean>(false)

    const handleClickTryAgain = () => {
        if (isLoading) return
        setIsLoading(true)
        onClick()
    }

    return (
        <div className="w-full flex flex-col items-end space-y-2">
            <div className={`min-h-[33px] w-full flex justify-start space-x-[15px]`}>
                <div className="h-full flex items-start">
                    <IconAssistant />
                </div>
                <div className="w-full py-3 px-5 bg-[#E7EFFF] rounded-sm flex flex-col space-y-1">
                    <span className="font-bold text-sm text-primary-3.2">An error occurred, please try again.</span>
                    <span className="font-medium text-xs text-[#7F91AB]">If the error persists, contact support.</span>
                </div>
            </div>
            <div className="rounded-full min-w-[112px] cursor-pointer border-2 border-[#E0EAFB] bg-[#F5F9FF] py-[5px] pl-[14px] pr-[21px] flex flex-row justify-center items-center space-x-2" onClick={handleClickTryAgain}>
                {
                    isLoading ?
                        <Loading className="!w-5 !h-5"/>
                        :
                        <>
                            <TbRefresh className="text-[#CBDBF7] w-3 h-3" />
                            <p className="font-bold text-sm text-primary-3.2">Try Again</p>
                        </>
                }
            </div>
        </div>
    )
}