import Skeleton from "../Skeleton"

export interface MessageButtonProps {
    text: string,
    className?: string,
    theme?: keyof typeof themes,
    position?: keyof typeof directions,
    loading?: boolean
    onClick?: () => void
}


const directions = {
    right: 'message-button-arrow-right rounded-br-none',
    left: 'message-button-arrow-left rounded-bl-none'
}

const themes = {
    default: {
        colors: 'before:border-r-primary-3 bg-primary-3 border-primary-3',
        text: 'text-white'
    },
    light: {
        colors: 'before:border-r-grey-1.1 bg-grey-1 border-grey-1.1',
        text: 'text-primary-2'
    },
}

export default function MessageButton({ text, theme = 'default', position = 'right', loading = false, className, ...props }: MessageButtonProps) {
    return (
        <>
            {
                loading ?
                    <Skeleton className="h-[43px] !w-[200px]"/>
                    :
                    <div className={`min-h-[43px] flex justify-end ${className ?? ''}`}>
                        <div {...props} className={`relative min-h-[43px] py-2 px-6 cursor-pointer flex justify-center items-center rounded border ${directions[position]} ${themes[theme].colors}`}>
                            <p className={`${themes[theme].text} text-base`}>{text}</p>
                        </div>
                    </div>
            }
        </>
    )
}