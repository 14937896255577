"use client"

import { ConfirmBookMeetingAction } from "@/interfaces/firebase";
import MessageAssistant from "../MessageAssistant";
import { Timestamp } from "firebase/firestore";
import { formatFirestoreTimestamp } from "@/functions/format";

export default function ConfirmMeeting({ meeting }: ConfirmBookMeetingAction) {
    let meet: any = meeting
    const hour = formatFirestoreTimestamp(new Timestamp(meet.start._seconds, meet.start._nanoseconds))

    return (
        <div>
            <MessageAssistant message={
                <span className="font-normal text-13px text-primary-1">I've added a meeting at <span className="font-bold text-primary-3">{hour} to your Schedule.</span></span>
            } />
        </div>
    )
}