"use client"

import { MdRefresh } from "react-icons/md";
import SuggestionButton from "../SuggestionButton";
import { RefObject, useState } from "react";

interface SuggestionListProps {
    suggestions: string[];
    referenceScroll: RefObject<HTMLDivElement>;
    onClick: (text: string) => void;
}

export default function SuggestionList({ suggestions, referenceScroll, onClick }: SuggestionListProps) {

    const [showSuggestedQnt, setShowSuggestedQnt] = useState<number>(2);
    const [isRotating, setIsRotating] = useState<boolean>(false);


    const handleLoadMore = () => {
        if (isRotating) return
        setIsRotating(true);
        setShowSuggestedQnt((prev) => {
            if (prev + 3 > suggestions.length) return suggestions.length;
            return prev + 3;
        });
        setTimeout(() => {
            referenceScroll.current?.scrollIntoView({
                behavior: "smooth",
                block: "center",
                inline: "end",
            });
        }, 200);
        setTimeout(() => setIsRotating(false), 1000);
    };

    return (
        <div className="h-auto rounded flex flex-col items-end space-y-[10px]">
            <p className="font-bold text-xs text-grey-2.2">suggested</p>
            {
                suggestions.map((text, key) => {
                    if (key > showSuggestedQnt) return;
                    return <SuggestionButton key={text + key} text={text} onClick={(text) => onClick(text)} />
                })
            }
            {
                suggestions.length > 2 &&
                <div className="bg-grey-1.1 py-[7px] px-3 rounded-full flex flex-row justify-center items-center space-x-[9px]" onClick={handleLoadMore}>
                    <MdRefresh
                        className={`text-[#4F6992] transition-transform duration-1000 ${isRotating ? 'rotate-[359deg]' : 'rotate-0'}`}
                    />
                    <p className="font-bold text-13px text-[#4F6992]">load more</p>
                </div>
            }
        </div>
    )
}