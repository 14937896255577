interface IconLocationProps {
    className?: string,
}

export default function IconLocation({ ...props }: IconLocationProps) {
    return (
        <svg width="8" height="11" viewBox="0 0 8 11" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path d="M4 5.5C4.275 5.5 4.5105 5.40217 4.7065 5.2065C4.9025 5.01083 5.00033 4.77533 5 4.5C4.99967 4.22467 4.90183 3.98933 4.7065 3.794C4.51117 3.59867 4.27567 3.50067 4 3.5C3.72433 3.49933 3.489 3.59733 3.294 3.794C3.099 3.99067 3.001 4.226 3 4.5C2.999 4.774 3.097 5.0095 3.294 5.2065C3.491 5.4035 3.72633 5.50133 4 5.5ZM4 10.5C2.65833 9.35833 1.65633 8.298 0.994 7.319C0.331667 6.34 0.000333333 5.43367 0 4.6C0 3.35 0.402167 2.35417 1.2065 1.6125C2.01083 0.870833 2.942 0.5 4 0.5C5.058 0.5 5.98933 0.870833 6.794 1.6125C7.59867 2.35417 8.00067 3.35 8 4.6C8 5.43333 7.66883 6.33967 7.0065 7.319C6.34417 8.29833 5.342 9.35867 4 10.5Z" fill="currentColor" />
        </svg>
    )
}