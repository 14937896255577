import IconAssistant from "../IconAssistant";
import Skeleton from "../Skeleton";

interface MessageAssistantProps {
	message: string | JSX.Element,
	className?: string,
	loading?: boolean
	onClick?: () => void
}

export default function MessageAssistant({ message, className, loading = false, ...props }: MessageAssistantProps) {
	return (
		<>
			{
				loading ?
					<Skeleton className="h-[33px]" />
					:
					<div className={`min-h-[33px] flex justify-start space-x-[15px] ${className ?? ""}`} {...props}>
						<div className="h-full flex items-start">
							<IconAssistant />
						</div>
						<span className="text-primary-1 font-medium text-13px leading-[145%] mt-[7px]">{message}</span>
					</div>
			}
		</>
	)
}
