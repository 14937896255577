import IconInformation from "../IconInformation";
import Skeleton from "../Skeleton";

interface AlertProps {
	text: string | JSX.Element
	className?: string
	loading?: boolean
}

export default function Alert({ text, loading = false, className }: AlertProps) {
	return (
		<>
			{
				loading ?
					<Skeleton className="h-[18px]" />
					:
					<div className={`flex flex-row ${className ?? ""}`}>
						<span className="mr-[7px] mt-[2px]">
							<IconInformation className="text-primary-3.2" />
						</span>
						<p className="font-normal text-13px leading-[135%] text-grey-3">{text}</p>
					</div>
			}
		</>
	)
}