interface actionAIProps {
    [x: string]: { message: string[], component: string, label: string }
}

export const actionAI: actionAIProps = {
    change_current_user_profile: {
        message: ["updating your profile to match your evolving journey..."],
        component: "large",
        label: "Let's keep your profile up-to-date."
    },
    change_user_avatar: {
        message: [""],
        component: "large",
        label: ""
    },
    change_user_socials: {
        message: ["syncing your social profiles for seamless connectivity..."],
        component: "large",
        label: "Updating your social profiles for better reach."
    },
    create_deal: {
        message: ["creating a deal that aligns with your strategic vision..."],
        component: "large",
        label: "Let's create a deal to advance your business goals."
    },
    update_deal: {
        message: ["fine-tuning the details of your deal for better opportunities..."],
        component: "large",
        label: "Refining your deal for better visibility."
    },
    make_commitment: {
        message: ["locking in your commitment for future collaboration..."],
        component: "large",
        label: "Your commitment is locked. Ready for next steps?"
    },
    update_commitment: {
        message: ["adjusting your commitment to better reflect your goals..."],
        component: "large",
        label: "Making necessary updates to your commitment."
    },
    search_for_profiles: {
        message: ["scanning profiles to find the perfect match for your network..."],
        component: "large",
        label: "Looking for good matches based on your profile. Here's one with potential:"
    },
    recommend_events: {
        message: ["curating events that match your professional aspirations...", "mapping out your next big opportunity..."],
        component: "large",
        label: "Finding the best events for you. Let’s explore what’s ahead."
    },
    search_for_deals: {
        message: ["finding deals that align with your business objectives...", "Finding the best deal for you..."],
        component: "large",
        label: "These deals might be of your interest:"
    },
    find_next_event: {
        message: ["locating the next event to elevate your experience...", "Identifying the next key event on your schedule..."],
        component: "large",
        label: "Let me find the next event for you."
    },
    show_conference_agenda: {
        message: ["preparing the conference agenda to streamline your schedule..."],
        component: "small",
        label: "Here’s your complete agenda for the conference."
    },
    make_introduction: {
        message: ["bridging the gap between key players for a powerful introduction..."],
        component: "small",
        label: "Connecting you with the right people."
    },
    give_the_user_options: {
        message: ["presenting options for you to choose the best path forward..."],
        component: "small",
        label: "Here are some options for you."
    },
    request_more_information_about_deal: {
        message: ["requesting more details to clarify the deal’s potential..."],
        component: "small",
        label: "Asking for more info on this deal."
    },
    offer_meeting_time: {
        message: ["offering potential meeting times for productive collaboration..."],
        component: "small",
        label: ""
    },
    confirm_meeting: {
        message: ["confirming the meeting details to solidify the partnership..."],
        component: "small",
        label: ""
    },
    cancel_meeting: {
        message: ["canceling the meeting and updating your schedule accordingly..."],
        component: "small",
        label: ""
    },
    reschedule_meeting: {
        message: ["rescheduling the meeting to accommodate everyone's availability..."],
        component: "small",
        label: "Rescheduling your meeting to a more convenient time."
    },
}