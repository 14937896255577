import useAuth from "@/hooks/useAuth";
import { useEffect, useState } from "react"
import IconMember from "../../../public/member-badge-icon.svg"
import IconSponsor from "../../../public/sponsor-badge-icon.svg"
import IconConnector from "../../../public/connector-badge-icon.svg"
import IconSuperConnector from "../../../public/super-connector-badge-icon.svg"
import Image from "next/image"


interface UserBadgeProps {
    badge?: string;
    className?: string;
    onClick?: () => void;
}

const badgeType = {
    Member: {
      bg: "bg-member-badge-gradient",
      boxShadow: "shadow-member-badge-shadow",
      text: "text-primary-2",
      border: "border-[#ffffff]",
      label: "Member",
      icon: <Image alt="Member" className="text-primary-2" src={IconMember} />
    },
    Connector: {
      bg: "bg-connector-badge-gradient",
      boxShadow: "shadow-connector-badge-shadow",
      text: "text-white",
      border: "border-[#5F9CFF]",
      label: "Connector",
      icon: <Image alt="Connector" className="text-white" src={IconConnector} />
    },
    "Super Connector": {
      bg: "bg-superconnector-badge-gradient",
      boxShadow: "shadow-member-badge-shadow",
      text: "text-primary-1",
      border: "border-[#E6FFA1]",
      label: "Super Connector",
      icon: <Image alt="Super Connector" className="text-primary-1" src={IconSuperConnector} />
    },
    Sponsor: {
      bg: "bg-sponsor-badge-gradient",
      boxShadow: "shadow-sponsor-badge-shadow",
      text: "text-white",
      border: "border-[#FFB34C]",
      label: "Sponsor",
      icon: <Image alt="Sponsor" className="text-white" src={IconSponsor} />
    },
}

export default function UserBadge({ badge, className, onClick }: UserBadgeProps) {
    const { currentUser } = useAuth()
    const [selectBadge, setSelectBadge] = useState<keyof typeof badgeType>("Member")

    useEffect(() => {
        if (badge) {
            setSelected(badge)
        } else {
            if (currentUser.tierAchieved) {
                setSelected(currentUser.tierAchieved)
            }
            else if (currentUser.tier) {
                setSelected(currentUser.tier)
            }
        }
    }, [badge, currentUser])

    const setSelected = (type: string) => {
        switch (type) {
            case "Connector":
                setSelectBadge("Connector")
                break;
            case "Super Connector":
                setSelectBadge("Super Connector")
                break;
            case "Sponsor":
                setSelectBadge("Sponsor")
                break;
            default:
                setSelectBadge("Member")
                break;
        }
    }

    return (
        <div className={`inline-flex flex-row space-x-[6px] py-[5px] pl-[9px] pr-[12px] rounded-full notranslate border-[3px] ${badgeType[selectBadge].bg} ${badgeType[selectBadge].border} ${badgeType[selectBadge].boxShadow} ${className ?? ""}`} onClick={() => onClick?.()}>
            {badgeType[selectBadge].icon}
            <p className={`font-bold text-xs text-nowrap ${badgeType[selectBadge].text}`}>{badgeType[selectBadge].label}</p>
        </div>
    )
}