"use client"

import IconRole from "../IconRole"
import IconLocation from "../IconLocation"
import Avatar from "../Avatar"

export interface EventCardProps {
    title: string,
    subtitle?: string,
    description?: string,
    location?: string,
    image?: string,
    className?: HTMLDivElement,
    onClick?: () => void
}

export default function EventCard({ title, subtitle, description, location, image, className, ...props }: EventCardProps) {

    return (
        <div className={`${className ?? ''} p-6 h-[171px] cursor-pointer flex flex-row space-x-2 items-center bg-grey-1 border border-grey-1.1`} {...props}>
            {
                image ?
                    <div>
                        <Avatar url={image} size="!w-auto h-[124px] max-w-[87px] z-10 !rounded-sm" />
                    </div>
                    : null
            }
            <div className="flex flex-col ml-6 justify-between h-full">
                <p className="text-primary-2 text-lg font-bold leading-[110%]">{title}</p>
                <p className="text-black-2 text-xs leading-[130%] font-medium">{subtitle}</p>
                <div>
                    {
                        description ?
                            <div className="flex flex-row items-center">
                                <IconRole className="text-primary-2 mr-[10px] h-3 w-auto" />
                                <p className="text-black-2 text-xs leading-[135%] font-medium">{description}</p>
                            </div>
                            :
                            null
                    }
                    {
                        location ?
                            <div className="flex flex-row items-center mt-[10px]">
                                <IconLocation className="text-primary-2 mr-[10px] h-3 w-auto" />
                                <p className="text-black-2 text-xs leading-[135%]  line-clamp-1 font-medium">{location}</p>
                            </div>
                            :
                            null
                    }
                </div>
            </div>
        </div>
    )
}