import { useState, useEffect } from "react";
import Skeleton from "../Skeleton";

export default function LoadingAiResponse() {
    const skeletons = [
        { width: "!w-[21px]", bgColor: "!bg-grey-1.1" },
        { width: "!w-[55px]", bgColor: "!bg-grey-1.1" },
        { width: "!w-[34px]", bgColor: "!bg-grey-1.1" },
        { width: "!w-[45px]", bgColor: "!bg-grey-1.1" },
        { width: "!w-[18px]", bgColor: "!bg-grey-1.1" },
        { width: "!w-[48px]", bgColor: "!bg-grey-1.1" },
        { width: "!w-[48px]", bgColor: "!bg-grey-1.1" },
        { width: "!w-[103px]", bgColor: "!bg-grey-1.1" },
        { width: "!w-[39px]", bgColor: "!bg-grey-1.1" }
    ]

    const [activeSkeleton, setActiveSkeleton] = useState(0)

    const [previousSkeleton, setPreviousSkeleton] = useState<number>(0)

    useEffect(() => {
        const interval = setInterval(() => {
            setPreviousSkeleton(activeSkeleton)
            setActiveSkeleton((prev) => (prev + 1) % skeletons.length)
        }, 200)

        return () => clearInterval(interval)
    }, [activeSkeleton, skeletons.length])

    return (
        <div className="h-full flex flex-wrap justify-start items-center gap-[5px]">
            {skeletons.map((skeleton, index) => (
                <Skeleton
                    key={index}
                    className={`h-3 ${skeleton.width} transition-colors duration-[400ms] ${index === activeSkeleton || index === previousSkeleton ? "!bg-[#D6E5FF]" : skeleton.bgColor}`}
                    isActive={false}
                />
            ))}
        </div>
    )
}
