import { useEffect, useRef, useState } from "react"
import IconAssistant from "../IconAssistant"
import IconClickIndicator from "../IconClickIndicator"
import IconIA from "../IconIA"
import IconRole from "../IconRole"
import Avatar from "../Avatar"

export interface ContactSuggestionProps {
	firstName: string,
	lastName: string,
	role?: string,
	company?: string,
	atributeList?: string[],
	image?: string,
	assistantMessage?: JSX.Element,
	className?: string,
	onClick?: () => void
}

export default function ContactSuggestion({ firstName, lastName, role, company, atributeList, image, assistantMessage, className, ...props }: ContactSuggestionProps) {
	const [height, setHeight] = useState('')
	const ref = useRef<HTMLDivElement>(null)
	const contactSuggestion = useRef<HTMLDivElement>(null)

	useEffect(() => {
		if (ref && ref.current) setHeight(`${ref.current.offsetHeight + 20 + 115}px`)
	})

	return (
		<div ref={contactSuggestion} className='flex-[0_0_auto] w-full' style={{ height: height }}>
			<div className="relative w-full flex">
				<div className="flex-grow">
					<div className={`min-h-[151px] w-full cursor-pointer flex flex-row justify-start bg-grey-1 border p-3 pl-6 border-grey-1.1 ${className ?? ''}`} {...props}>
						<div className="flex flex-row w-[calc(100%-28px)] justify-start h-full">
							{
								image ?
									<div className="w-[65px]">
										<Avatar url={image} size="!w-[65px] h-[65px] z-10" />
									</div>
									:
									<div className="w-[65px]">
										<div className="w-[65px] h-[65px] rounded-full border-grey-1.1 border-[3px] bg-white flex justify-center items-center">
											<p className="text-primary-3 font-bold text-3xl notranslate">{`${firstName?.substring(0, 1).toUpperCase()}${lastName?.substring(0, 1).toUpperCase()}`}</p>
										</div>
									</div>
							}
							<div className="h-full ml-5 w-[calc(100%-85px)]">
								<p className="text-xl text-primary-3 font-bold leading-[135%] notranslate">{firstName} {lastName}</p>
								<div>
									{role && <p className="text-sm text-black-2 font-medium leading-[130%] line-clamp-1">{role},</p>}
									{company && <p className="text-sm text-black-2 font-medium leading-[130%] line-clamp-1">{company}</p>}
								</div>
								{
									atributeList && atributeList.length ?
										<div className="flex flex-row items-center pt-[10px]">
											<IconRole className="text-primary-2 mr-[6px] h-[10px] w-[10px]" />
											<p className="text-11px text-black-2 w-[calc(100%_-_10px)] font-medium leading-[130%] line-clamp-2">{atributeList.join(', ')}</p>
										</div>
										: null
								}
							</div>
						</div>
						<div className="absolute right-0 top-0 w-[35px] h-[35px] bg-grey-1.1 flex justify-center items-center">
							<IconClickIndicator className="text-primary-3.2 w-3 h-3" />
						</div>
					</div>
				</div>
				{
					assistantMessage ?
						<div ref={ref} className="absolute top-[130px] left-[32px] min-w-[250px] w-[74%] h-auto bg-primary-3 flex flex-row justify-between pl-[13px] pb-3 pt-[6px] pr-[6px] rounded-sm rounded-bl-none border border-grey-1">
							<div className="pt-[6px] flex flex-row">
								<IconAssistant />
								<div className="ml-[10px] !text-13px text-white flex items-center w-[calc(100%_-_43px)]">
									<span>{assistantMessage}</span>
								</div>
							</div>
							<IconIA className="text-grey-1" />
						</div>
						: null
				}
			</div>
		</div>
	)
}