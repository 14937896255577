import { ReactElement } from "react"
import Avatar from "../Avatar"
import Markdown from "react-markdown"

interface MessageProps {
	text: string,
	firstName?: string,
	premiumMember?: boolean,
	hour?: string,
	image?: string,
	theme?: keyof typeof themes,
	position?: keyof typeof directions,
	className?: HTMLDivElement,
}

const directions = {
	right: 'message-button-arrow-right',
	left: 'message-button-arrow-left'
}

const rounded = {
	right: 'rounded-br-none',
	left: 'rounded-tl-none'
}

const themes = {
	UserMessage: {
		colors: 'before:border-r-primary-3 bg-primary-3 border-primary-3',
		text: 'text-white font-normal'
	},
	AnswerMessage: {
		colors: 'before:border-r-primary-4 bg-grey-1 border-primary-4',
		text: 'text-black-3 font-normal'
	},
}

export default function Message({ text, firstName, hour, theme = 'UserMessage', premiumMember = false, position = 'right', image, className }: MessageProps) {

	return (
		<div className={`flex flex-row items-end ${position == 'left' ? 'justify-start' : 'justify-end'}`}>
			{
				position == 'left' ?
					<div className="mr-[14px] h-full flex items-start">
						{
							image ?
								<Avatar url={image} size="!w-[20px] h-[20px]" isHighlight={premiumMember} />
								:
								<>
									{
										firstName ?
											<div className={`border border-grey-2.2 w-[20px] h-[20px] rounded-full overflow-hidden flex justify-center items-center text-primary-1 text-xs`}>{firstName.substring(0, 1).toUpperCase()}</div>
											:
											<div className="w-[20px]"></div>
									}
								</>
						}
					</div>
					: null
			}
			<div className={`relative min-h-[43px] max-w-[calc(100%_-_60px)] py-2 px-4 ${position == "left" ? "" : "pr-4"} flex justify-center items-center rounded border ${position == "left" && !image && !firstName ? null : directions[position]} ${rounded[position]} ${themes[theme].colors} ${premiumMember ? "before:!border-r-highlight !bg-primary-1 !border-highlight" : ""} ${className ?? ''}`}>
				<Markdown className={`${themes[theme].text} ${premiumMember && "!text-white"} text-sm break-words`}>{text}</Markdown>
			</div>
			{
				hour && position == "left" ?
					<span className="text-grey-2.2 font-medium text-9px leading-[140%] pl-1">{hour}</span>
					: null
			}
		</div>
	)
}